.card-dashboard{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05) !important;
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.card-dashboard:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card-dashboard h3{
  font-weight: 600;
}

.card-dashboard img{
  position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px;
}

.card-dashboard-1{
  background-image: url('/img/icons8-schedule-96.png');
      background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
}

.card-dashboard-2{
   background-image: url('/img/icons8-fund-accounting-96.png');
      background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
}

.card-dashboard-3{
   background-image: url('/img/icons8-account-96.png');
      background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
}

.map-card-1 {
  background-color: #ffffff;
  padding: 10px;
  position: absolute;
  top: 17px;
  width: 300px;
  margin-left: 17px;
  left: 0;
  right: 0;
  z-index: 10; 
  text-align: center; 
  box-shadow:  5px 5px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05) !important
}

.map-card-1>p {
  font-size: 12px;
  margin-bottom: 5px;
}
.map-card-1>p>b {
  font-size: 14px;
}


