
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}

/* Handle */
::-webkit-scrollbar-thumb {
    /*background: rgba(189, 189, 189, 0.8); */
    background: #b0bec5;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
    /*background: rgba(156, 156, 156, 0.616); */
    background: #808e95;
}

.form-text--error {
    color: red;
    font-size: smaller;
}

.react-calendar-heatmap text {
  font-size: 7px;
}

.lmask {
    position: fixed;
    height: 100%;
    width: 100%; 
    background-color: #000;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    opacity: 0.4;
    &.fixed {
      position: fixed;
    }
    &:before {
      content: '';
      background-color: rgba(0,0,0,0);
      border: 5px solid rgba(0,183,229,0.9);
      opacity: .9;
      border-right: 5px solid rgba(0,0,0,0);
      border-left: 5px solid rgba(0,0,0,0);
      border-radius: 50px;
      box-shadow: 0 0 35px #2187e7;
      width: 50px;
      height: 50px;
      -moz-animation: spinPulse 1s infinite ease-in-out;
      -webkit-animation: spinPulse 1s infinite linear;
  
      margin: -25px 0 0 -25px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
    &:after {
      content: '';
      background-color: rgba(0,0,0,0);
      border: 5px solid rgba(0,183,229,0.9);
      opacity: .9;
      border-left: 5px solid rgba(0,0,0,0);
      border-right: 5px solid rgba(0,0,0,0);
      border-radius: 50px;
      box-shadow: 0 0 15px #2187e7;
      width: 30px;
      height: 30px;
      -moz-animation: spinoffPulse 1s infinite linear;
      -webkit-animation: spinoffPulse 1s infinite linear;
  
      margin: -15px 0 0 -15px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .page-controls {
      z-index: -1 !important;
  }
  
  @-moz-keyframes spinPulse {
    0% {
      -moz-transform:rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #2187e7;
    }
    50% {
      -moz-transform: rotate(145deg);
      opacity: 1;
    }
    100% {
      -moz-transform: rotate(-320deg);
      opacity: 0;
    }
  }
  @-moz-keyframes spinoffPulse {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spinPulse {
    0% {
      -webkit-transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #2187e7;
    }
    50% {
      -webkit-transform: rotate(145deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-320deg);
      opacity: 0;
    }
  }
  @-webkit-keyframes spinoffPulse {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

.invoice-title h2, .invoice-title h3 {
    display: inline-block;
}

.table > tbody > tr > .no-line {
    border-top: none;
}

.table > thead > tr > .no-line {
    border-bottom: none;
}

.table > tbody > tr > .thick-line {
    border-top: 2px solid;
}

.watermark 
{
    font-size: 6em;
    opacity: 0.5;
    color: red;
    position: absolute;
    top: 50%;
    left: 40%;
    display:block;
    -webkit-transform: rotate(-45deg); 
    -moz-transform: rotate(-45deg); 
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); //For IE support
}

@media only screen and (max-width: 500px) {
  .watermark {
      font-size: 4em;
      opacity: 0.5;
      color: red;
      position: absolute;
      top: 40%;
      left: 10%;
      display:block;
      -webkit-transform: rotate(-45deg); 
      -moz-transform: rotate(-45deg); 
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); //For IE support
  }
}

@media print {
    .watermark {
        top: 25%;
        left: 15%;
    }
}

input.number {
    text-align: right
}

.number {
  text-align: right
}

.fc-view.fc-dayGridMonth-view.fc-dayGrid-view>table {
  background: #ffffff
}

canvas {
  background-color: #ffffff;
}

.fc-toolbar h2 {
  font-size: 1.5em;
}

.fc-button {
  font-size: 0.85em;
}
