@media print {
  .page-controls,
  .sidebar,
  .loader-wrap {
    display: none;
  }

  .content-wrap {
    position: static;
  }

  .content {
    margin: 0;
    padding: 0;
  }

  .table,
  .table td,
  .table th {
    background: $white !important;
    border: none !important;
  }

  .table td {
    border-top: 1px solid $gray-600 !important;
  }

  .hidden-print {
    display: none !important;
  }

  #page_title_a,
  #tabs1,
  #summary,
  #payment,
  #action_btn_pannel,
  #bread-crumb,
  #nav-drawer,
  #nav-header,
  #nav-sider,
  #team-label,
  #action_search,
  #action_payment,
  #action_cancel {
    display: none;
  }
}
